import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Field, Form } from "formik";
import * as yup from "yup";
import { contactUs, onLoad } from '../../../general_redux/actions';
import location from '../../../icons/contact_location.svg';
import phone from '../../../icons/contact_telephone.svg';
import email from '../../../icons/contact_email.svg';
import beehaz11 from '../../../icons/Beehaz_11_help_center.png';
import beehaz12 from '../../../icons/Beehaz_12_live_chat.png';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import styles from './contactUs.module.css';
import generalStyles from '../websiteMain.module.css';
//cusontsize

function ContactUs(props) {
    const dispatch = useDispatch();
    const contactUsSchema = yup.object().shape({
        firstName: yup.string().required('Your name is equired'),
        secondName: yup.string().required('Your name is equired'),
        emailAddress: yup.string().email('Please enter a valid email address').required('Email address is required'),
        message: yup.string().required('Your message is equired'),
    });

    const handleSubmit = (data) => {
        const payload = {
            firstName: data.firstName,
            lastName: data.secondName,
            email: data.emailAddress,
            message: data.message,
        }
        dispatch(contactUs(payload));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={generalStyles.mainLayout}>
            <Header />
            <div className={`${generalStyles.container} ${styles.mainContainer} mt-4 w-100`}>
                <div className={styles.contactHeader}>
                    <h1 className={`${generalStyles.generalH1}`}>Contact us</h1>
                    <br />
                    {/* Styles: section 1 */}
                    <section className={`${styles.alterContainer}`}>
                        <div className={styles.addressBox}>
                            <div className={styles.iconHead}>
                                <img alt="Location icon" src={location} />
                                <span>Luzernstrasse 31 <br /> 6208 Oberkirch <br /> Switzerland</span>
                            </div>
                            <div className={styles.iconHead}>
                                <img alt="Phone icon" src={phone} />
                                <span>Switzerland +41 76-594-4719 <br /> US:+1 917-764-5080</span>
                            </div>
                            <div className={styles.iconHead}>
                                <img alt="Email icon" src={email} />
                                <span>admin@beehaz.com</span>
                            </div>
                        </div>
                        {/* <div className={styles.separator}></div> */}
                        <div className={generalStyles.inputArea}>
                            <div>
                                <Formik
                                    initialValues={{
                                        firstName: '',
                                        secondName: '',
                                        emailAddress: '',
                                        message: '',
                                    }}
                                    validationSchema={contactUsSchema}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                >
                                    {({ errors, touched, values, handleSubmit, handleChange }) => (
                                        //form_body not found
                                        <Form className={styles.form_body}>
                                            <div className={`d-md-flex `} >
                                                <div className={`${generalStyles.form_wrapper} mr-md-2`}>
                                                    <div className={generalStyles.form_title}>{"First name"}</div>
                                                    <Field
                                                        type={"text"}
                                                        name={"firstName"}
                                                        onChange={handleChange}
                                                        className={errors.firstName ? generalStyles.form_entry_err : generalStyles.form_entry}
                                                    />
                                                    <div className={errors.firstName ? generalStyles.form_error : generalStyles.form_error_hidden}>{errors.firstName}</div>
                                                </div>
                                                <div className={generalStyles.form_wrapper}>
                                                    <div className={generalStyles.form_title}>{"Second name"}</div>
                                                    <Field
                                                        type={"text"}
                                                        name={"secondName"}
                                                        onChange={handleChange}
                                                        className={errors.secondName ? generalStyles.form_entry_err : generalStyles.form_entry}
                                                    />
                                                    <div className={errors.secondName ? generalStyles.form_error : generalStyles.form_error_hidden}>{errors.secondName}</div>
                                                </div>
                                            </div>
                                            <div className={generalStyles.form_wrapper}>
                                                <div className={generalStyles.form_title}>{"Email"}</div>
                                                <Field
                                                    type={"email"}
                                                    name={"emailAddress"}
                                                    onChange={handleChange}
                                                    className={errors.emailAddress ? generalStyles.form_entry_err : `w-100 ${generalStyles.form_entry}`}
                                                />
                                                <div className={errors.emailAddress ? generalStyles.form_error : generalStyles.form_error_hidden}>{errors.emailAddress}</div>
                                            </div>
                                            <div className={generalStyles.form_wrapper}>
                                                <div className={generalStyles.form_title}>{"Message"}</div>
                                                <Field
                                                    type={"textarea"}
                                                    name={"message"}
                                                    as={"textarea"}
                                                    rows={3}
                                                    onChange={handleChange}
                                                    className={errors.message ? generalStyles.form_entry_err : `w-100 ${generalStyles.form_entry}`}
                                                />
                                                <div className={errors.message ? generalStyles.form_error : generalStyles.form_error_hidden}>{errors.message}</div>
                                            </div>
                                            <div>
                                                <button
                                                    className={`${generalStyles.generalBtnSmaller} mt-2 ml-auto`} type="submit">Send</button>
                                            </div>
                                            <br />
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </section>
                    {/* Styles: section 2 */}
                    {/* <section className={styles.leftClass}>
                        <div>
                            <h2 className={`${generalStyles.generalH2}`}>The help center</h2><br />
                        </div>
                        <div className={styles.helpContainer}>
                            <div>
                                <p className={`${generalStyles.generalP}`}>
                                    Check out our user guides and videos in our help center!
                                    We compiled a lot of resources to guide you through your discovery of the software, including step-by-step short informative videos!
                                </p>
                                <p className={`${generalStyles.generalP}`}>
                                    In our resources page you shall also find our rental management blog, filled with insights and tips from professional vacation managers you will love!
                                </p>
                            </div>
                            <div>
                                <img src={beehaz11} alt="Magnifying glas showing help icon on Beehaz's main software interface" />
                            </div>
                        </div>
                        <p className={`${generalStyles.generalP}`}>
                            If you have already visited our help center and have not found the answer to your question, we shall be happy to assist you! Don't hesitate contacting us through the contact form above or texting us in the live chat.
                        </p>
                    </section> */}
                    <section className={styles.leftClass}>
                        <div>
                            <h2 className={`${generalStyles.generalH2}`}>Talk to us</h2><br />
                        </div>

                        <p className={`${generalStyles.generalP}`}>
                            Our app is almost ready... but you are welcome to start using it for free!
                        </p>
                        <p className={`${generalStyles.generalP}`}>
                            If you need help, don't hesitate contacting us through the contact form above or texting us in the live chat!
                        </p>
                    </section>
                </div>
                {/* <button className={`${generalStyles.generalBtnSmaller} center mt-4 m-auto`}>
                    Check out our user guides
                </button> */}
                <div className={`${styles.supportImg} text-center `}>
                    <img src={beehaz12} alt="Tablet displaying Beehaz calendar interface and chat support" />
                </div>
            </div>
            <br />
            <Footer />
        </div>
    )
}

export default ContactUs;