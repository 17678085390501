import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { onLoad } from '../../../general_redux/actions';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loading from '../../../components/loading';
import comma from '../../../icons/comma.svg';
import Beehaz_01_main from '../../../icons/Beehaz_01_main.png';
import Beehaz_02_front_desk from '../../../icons/Beehaz_02_front_desk.png';
import Beehaz_03_fixed_term from '../../../icons/Beehaz_03_fixed_term.png';
import Beehaz_04_invoices from '../../../icons/Beehaz_04_invoices.png';
import Beehaz_05_ical from '../../../icons/Beehaz_05_ical.png';
import Beehaz_06_guests from '../../../icons/Beehaz_06_guests.png';
import Beehaz_07_work_anywhere from '../../../icons/Beehaz_07_work_anywhere.png';
import Beehaz_08_grow_with_you from '../../../icons/Beehaz_08_grow_with_you.png';
import Beehaz_09_more_for_less from '../../../icons/Beehaz_09_more_for_less.png';
import PrevArrow from '../../../icons/prev-arrow.svg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import generalStyles from '../websiteMain.module.css';
import styles from './home.module.css';

function Home() {
    const dispatch = useDispatch();

    const [selectedItem, setSelectedItems] = useState(0);

    const handleRedirect = () => {
        window.open("https://youtu.be/9ljggH22shY", "_blank", "noopener,noreferrer");
    };

    useEffect(() => {
        dispatch(onLoad());
        window.scrollTo(0, 0);
    }, []);

    const actionForAddSubscriber = useSelector(({ subscribers }) => subscribers && subscribers.actionForAddSubscriber);

    return (
        <>
            <Loading loadingStatus={actionForAddSubscriber.loading} />
            <div className={generalStyles.mainLayout}>
                <Header />
                {/* Styles: Section 1 */}
                <section className={`${generalStyles.container}`}>
                    <div className={`${styles.section1}`}>
                        <div className={`${styles.section1Left} `}>
                            <h1 className={`${generalStyles.generalH1} ${styles.homeH1}`}>The Rental Management Software</h1>
                            <p className={`${styles.HomeH1Subtitle}`}>That is simple to use <br /> and fits your budget</p>
                            <button
                                className={`${generalStyles.generalBtn} `}
                                onClick={handleRedirect}>Watch Video</button>
                        </div>
                        <div className={`${styles.section1Right}  `}>
                            <div className={`${styles.section1Img} `}>
                                <img
                                    src={Beehaz_01_main}
                                    alt={"Beehaz Rental Management Software Desktop View"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${generalStyles.textQuotationContainer}`}>
                        <span className={`${generalStyles.textQuotation}`}>
                            <img src={comma} className={generalStyles.leftSvgComma} alt="Quotation marks" />
                            <blockquote>
                                The must-have software if you manage rental offerings of both short- and long-term stays
                            </blockquote>
                            <img src={comma} className={generalStyles.rightSvgComma} alt="Quotation marks" />
                        </span>
                    </div>
                    <div className={styles.sectionParagraphContainer}>
                        <p className={generalStyles.generalP}>Our goal was to create a rental management software that is intuitive, simple, but has everything you need at an affordable price. It takes less than 5 minutes to set up your account and take bookings.</p>
                        <p className={generalStyles.generalP}>Built for short- and long-term rental managers who would like to simplify their daily operations, keep track of payment and guest information, and work from anywhere.</p>
                        <p className={generalStyles.generalP}>If you are new to hosting or looking for a rental management software solution that better fits your needs and budget, you have come to the right place!</p>
                    </div>
                </section>

                {/* Styles: Section 2 */}
                <section className={`${styles.sliderBgcolor}`}>
                    <div className={`${styles.sliderSlide} ${generalStyles.container}`}>
                        <Carousel
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <button
                                        type="button"
                                        className={`control-arrow ${styles.slideArrow}`}
                                        onClick={onClickHandler}
                                        title={label}
                                        style={{ left: '-150px' }}
                                    >
                                        <img alt="Previous" src={PrevArrow} />
                                    </button>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <button
                                        type="button"
                                        className={`control-arrow ${styles.slideArrow}`}
                                        onClick={onClickHandler}
                                        title={label}
                                        style={{ right: '-150px' }}
                                    >
                                        <img alt="Next" style={{ transform: 'scaleX(-1)' }} src={PrevArrow} />
                                    </button>
                                )
                            }
                            onChange={(val) => setSelectedItems(val)}
                            // renderArrowPrev = {(val)=>setSelectedItems(val)}
                            // statusFormatter={(current, total) => <div className={styles.slideIndex}>{`${current} of ${total}`}</div>}
                            statusFormatter={(current, total) => <div className={styles.slideIndex}></div>}
                            showThumbs={false}
                        >
                            <div style={{ padding: "1em" }}>
                                <div>
                                    <h2 className={`${generalStyles.generalH2} ${styles.carouselHeadings}`}>
                                        Front-desk operations
                                    </h2>
                                </div>
                                <div>
                                    <img src={Beehaz_02_front_desk} alt="Front desk operations in Beehaz" />
                                </div>
                                <div>
                                    <p className={`${styles.sliderTextone}`}>
                                        Take/edit bookings, block dates  & color-code reservations<br />
                                        Check availability from an infinite-scroll calendar<br />
                                        Send emails & WhatsApp messages with one click
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2 className={`${generalStyles.generalH2} ${styles.carouselHeadings}`}>
                                        Monthly Bookings
                                    </h2>
                                </div>
                                <div>
                                    <img src={Beehaz_03_fixed_term} alt="Monthly Bookings in Beehaz" />
                                </div>
                                <div>
                                    <p className={`${styles.sliderTextone}`}>
                                        Easy input of fixed price stays (monthly and weekly bookings)<br />
                                        Book and manage long-time stays efficiently<br />
                                        Perfect to track payments of fixed-term stays
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2 className={`${generalStyles.generalH2} ${styles.carouselHeadings}`}>
                                        Custom Invoices
                                    </h2>
                                </div>
                                <div>
                                    <img src={Beehaz_04_invoices} alt="Custom Invoices in Beehaz" />
                                </div>
                                <div>
                                    <p className={`${styles.sliderTextone}`}>
                                        Create, download and send invoices instantly<br />
                                        Schedule invoices for a future date<br />
                                        Add a payment button to accept credit card payments
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2 className={`${generalStyles.generalH2} ${styles.carouselHeadings}`}>
                                        Synchronize your Calendar
                                    </h2>
                                </div>
                                <div>
                                    <img src={Beehaz_05_ical} alt="Synchronize other Calendars with Beehaz" />
                                </div>
                                <div>
                                    <p className={`${styles.sliderTextone}`}>
                                        Check availability from an infinite scroll calendar<br />
                                        Feature comming soon: synchronize availability across channels<br />
                                        Sync with Google Calendar, VRBO, Airbnb, and more
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2 className={`${generalStyles.generalH2} ${styles.carouselHeadings}`}>
                                        Guest Management
                                    </h2>
                                </div>
                                <div>
                                    <img src={Beehaz_06_guests} alt="Guest Management in Beehaz" />
                                </div>
                                <div>
                                    <p className={`${styles.sliderTextone}`}>
                                        Save important guest information<br />
                                        Keep track of a guest's past bookings and invoices<br />
                                        Speed up the reservation process when a guest re-books
                                    </p>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </section>

                {/* Styles: section 3 */}
                <section className={`${styles.backgroundBWhite}  my-3`}>
                    <div className={`${generalStyles.container}`}>
                        <div className={`row justify-content-center align-items-center`}>
                            <div className={`col-md-6 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                                <h2 className={`${generalStyles.generalH2}  ${styles.rightAlignText}`}>Work from anywhere</h2><br />
                                <p className={`${generalStyles.generalP}`}>Manage your rental properties on the go. You should be able to perform the same tasks on your smartphone that you can complete from your computer.</p>
                                <p className={`${generalStyles.generalP}`}>Organize your calendar, bookings, and guests. All our software features are available to you independently of your screen size.</p>
                                <p className={`${generalStyles.generalP}`}>Check payment, handle arrivals, update a reservation - keep your business organized as inquiries and change requests can come about at any minute.</p>
                                <p className={`${generalStyles.generalP}`}>If you need to call or send a quick WhatsApp message to your guest, a click will be all you need!</p>
                            </div>
                            <div className={`col-lg-5 col-md-6`} >
                                <div className={`${styles.sectionImgVt} mx-auto `} style={{ maxHeight: 'unset' }}>
                                    <img
                                        src={Beehaz_07_work_anywhere}
                                        className={`${styles.HomeVerticalImages} w-100`}
                                        alt={"Phone showing Beehaz's rates module"}
                                    />
                                    <div className={styles.imgLayout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Styles: section 4 */}
                <section className={`${styles.backgroundBGgray}  my-3`}>
                    <div className={`${generalStyles.container}`}>
                        <div className={`row justify-content-center align-items-center`}>
                            <div className={`col-lg-5 col-md-6`}>
                                <div className={`${styles.sectionImgVt} mx-auto`} style={{ maxHeight: 'unset' }}>
                                    <img
                                        src={Beehaz_08_grow_with_you}
                                        className={`${styles.HomeVerticalImages}`}
                                        alt={"Computer and coffee mug"}
                                    />
                                    <div className={styles.imgLayout} />
                                </div>
                            </div>
                            <div className={`col-md-6 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                                <h2 className={`${generalStyles.generalH2} ${styles.leftAlignText}`}>
                                    More for less
                                </h2>
                                <p className={`${generalStyles.generalP}`}>Beehaz is a cloud solution for hosts designed by hosts. Entrepreneurs who themselves test and use Beehaz in their daily operations.</p>
                                <p className={`${generalStyles.generalP}`}>We will keep improving, and we look forward to perfecting our interface and adding the features you desire - and we want to do this while offering the best prices in the market. </p>
                                <p className={`${generalStyles.generalP}`}>Coming up next: payment gateway, API connection, and website builder!</p>
                                <p className={`${generalStyles.generalP}`}>Help us tailor the software you want to have. If you have ideas for features that are not yet on our list <a href="/features" className={`${generalStyles.generalA}`}>click here to share your thoughts</a>!</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Styles: section 5 */}
                <section className={`${styles.backgroundBWhite}  my-3`}>
                    <div className={`${generalStyles.container}`}>
                        <div className={`row justify-content-center align-items-center`}>
                            <div className={`col-md-6 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                                <h2 className={`${generalStyles.generalH2}  ${styles.rightAlignText}`}>Short- and long-term</h2><br />
                                <p className={`${generalStyles.generalP}`}>The Beehaz software serves as a centralized platform for properties rented out by the night, week, or month.</p>
                                <p className={`${generalStyles.generalP}`}>You can set daily, weekly, and monthly rates, and make multiple reservations with one click using the fixed-term booking tool!</p>
                                <p className={`${generalStyles.generalP}`}>Beehaz can also help you avoid double bookings by synchronizing your calendar across multiple platforms, such as Airbnb and VRBO.</p>
                                {/* <p className={`${generalStyles.generalP}`}>You can use Beehaz for free (forever) or start our Plus Plan trial today - no credit card required!</p> */}
                                <p className={`${generalStyles.generalP}`}>You can use Beehaz for free during our beta phase! Send us a message to become a beta tester and get price benefits for a lifetime!</p>
                            </div>
                            <div className={`col-lg-5 col-md-6`} >
                                <div className={`${styles.sectionImgVt} mx-auto `} style={{ maxHeight: 'unset' }}>
                                    <img
                                        src={Beehaz_09_more_for_less}
                                        className={`${styles.HomeVerticalImages} w-100`}
                                        alt={"Phone displaying Beehaz's iCal sychronization module"}
                                    />
                                    <div className={styles.imgLayout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Styles: section 6 */}
                <section className={styles.section6}>
                    <div className={`${generalStyles.container}`}>
                        <h2 className={`${generalStyles.generalH2} ${styles.textCenter} section52`}>
                            Don't just take our word for it: try it! <br /> <span className={`${generalStyles.generalP}`}>Try for free. Cancel any time. No credit card required.</span>
                        </h2>
                        <div className={styles.videoDiv}>
                            <video
                                width="100%"
                                controls
                                loop
                                muted
                                preload={"auto"}
                                alt={"Easy to use rental management software"}
                            >
                                <source
                                    src="https://video.wixstatic.com/video/e30525_66592b5af6384254af3f3be3696cdc41/1080p/mp4/file.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                    </div>
                </section>
                {/* Styles: section 7 */}
                <section className={`${generalStyles.container} my-3`}>
                    <div className={`${generalStyles.textQuotationContainer}`}>
                        <span className={`${generalStyles.textQuotation}`} >
                            <img src={comma} className={generalStyles.leftSvgComma} alt="Quotation marks" />
                            <blockquote className={`${generalStyles.textQuotation}`}>
                                Beehaz's mission is to make rental management easy and affordable. Simplify daily operations and grow your business.
                            </blockquote>
                            <img src={comma} className={generalStyles.rightSvgComma} alt="Quotation marks" />
                        </span>
                    </div>
                    <div className={`d-flex justify-content-center `}>
                        <Link role="button" className={`${generalStyles.generalBtn} mt-4 d-inlineblock`} to={'/register'}>Check out our plans</Link>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}
export default Home;