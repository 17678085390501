import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onLoad } from "../../../general_redux/actions";
import vector from '../../../icons/VectorCheck.png';
import Beehaz10 from '../../../icons/Beehaz_10_features.png';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import generalStyles from '../websiteMain.module.css';
import styles from './features.module.css';

function Features(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(onLoad());
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className={styles.featuresMain}>
                <h1 className={`mb-5 ${generalStyles.generalH1}`}>The features you need in a rental management software</h1>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span className={`${generalStyles.generalH3}`}>Front desk system</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Add reservations and block dates</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Add multiple weekly/monthly bookings at once</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Color coded payment status</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Make notes of special requests</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Color-code reservations</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Infinite scroll for ease of viewing</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Guest information at a glance</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Whatsapp or email your guest with a click</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Channel manager (comming soon!) </span>
                            </div>
                            <div className={styles.col2}>

                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span className={`${generalStyles.generalH3}`}>Guest management (CRM)</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Save guest details</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Check past bookings and invoices</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Download guest list as Excel file</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span className={`${generalStyles.generalH3}`}>Rate and rental management</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Set different currencies for each rental</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Rates calendar showing availabilty</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Set nightly/weekly/monthly rates</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span className={`${generalStyles.generalH3}`}>Invoicing and direct payments</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span className={`${generalStyles.generalH3}`}>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Flexible invoicing</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>See and download invoice history</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Scheduled invoice sending</span>
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span className={`${generalStyles.generalTableCell}`}>Receiving direct payments (Stripe) (comming soon!) </span>
                            </div>
                            <div className={styles.col2}>

                            </div>
                            <div className={styles.col2}>
                                <img src={vector} alt="Check mark" />
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br />
                {/* <div className={styles.section5}>
                    <h1 className={styles.textCenter}>Coming up soon</h1>
                    <h2 className={`${styles.col1} justify-content-center w-100`}>What you can expect in 2022</h2>
                    <br/>
                    <div className={styles.leftClass}>
                        <p>The Beehaz development team is currently working on the following features:</p>
                        <div>
                            <ul>
                                <li>Paypal integration</li>
                                <li>Booking engine</li>
                                <li>Website builder</li>
                            </ul>
                        </div>
                        <p>We are committed to offering the most important features to enable our users to grow their businesses. Continuous development and improvement to our services are only possible with your feedback, and we are happy to hear from you! Reach out via live chat or contact form and share your thoughts on how we can enhance our software offering.</p>
                    </div>

                    <button className={`${styles.mainBut} mt-4`} onClick={(e)=>e.preventDefault()}>
                        Share your thoughts!
                    </button>
                </div> */}

                <div className={`${styles.feturesimg} text-center `}>
                    <div>
                        <img src={Beehaz10} alt="Laptop displaying an invoice created with Beehaz" />
                    </div>
                    <Link role="button" className={`${generalStyles.generalBtnSmaller} mt-4 d-inline-block`} to={'/register'}>Get started!</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Features;