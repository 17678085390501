import React, { useState } from 'react';
import styles from '../pricing.module.css';
import generalStyles from '../../websiteMain.module.css';
import { Link } from 'react-router-dom';

function GreyArea() {
  const [numberOfUnits, setNumberOfUnits] = useState(3);
  const [annual, setAnnual] = useState(true);
  const [monthly, setMonthly] = useState(false);
  const monthlyPrice = ((numberOfUnits * 7) + 15).toFixed(2);
  const yearlyPrice = (((numberOfUnits * 630) + 1350) / 100).toFixed(2);

  const monthlyPricePaidYear = (((monthlyPrice * 100) * 12) / 100).toFixed(2);
  const yearlyPricePaidYear = (((yearlyPrice * 100) * 12) / 100).toFixed(2);

  const handleMinus = () => {
    if (numberOfUnits - 1 > 0) {
      setNumberOfUnits(numberOfUnits - 1);
    }
  };

  const handlePlus = () => {
    if (numberOfUnits + 1 <= 50) {
      setNumberOfUnits(numberOfUnits + 1);
    }
  };
  return (
    <div className={styles.grey_box} >
      <div className={styles.mainContainer}>
        <div className={styles.grey_box_header}>
          <h2 className={`${generalStyles.generalH2}`}>Flexibility: monthly and yearly payment options</h2>
        </div>
        <div className={styles.box_area}>
          <div className={styles.box_area_left}>
            <div className={styles.box_area_header}>Select Units:</div>
            <div className={styles.box_area_number_area}>
              <div className={styles.box_area_btn_minus} onClick={handleMinus}>
                <div className={styles.box_area_btn_sign_minus}>-</div>
              </div>
              <div className={styles.box_area_number}> {numberOfUnits} </div>
              <div className={styles.box_area_btn_plus} onClick={handlePlus}>
                <div className={styles.box_area_btn_sign_plus}>+</div>
              </div>
            </div>
            <div className={styles.box_area_header}>Select Billing Cycle:</div>
            <div
              className={styles.box_area_radio_btn_area}
              onClick={() => {
                setAnnual(true);
                setMonthly(false);
              }}
            >
              <div className={annual ? styles.box_area_radio_btn : styles.box_area_radio_btn_inactive}> <div></div> </div>
              <div className={styles.box_area_radio_txt}>Annual billing (20% off)</div>
            </div>
            <div
              className={styles.box_area_radio_btn_area}
              onClick={() => {
                setAnnual(false);
                setMonthly(true);
              }}
            >
              <div className={monthly ? styles.box_area_radio_btn : styles.box_area_radio_btn_inactive}> <div></div> </div>
              <div className={styles.box_area_radio_txt}>Monthly billing</div>
            </div>
          </div>
          <div className={styles.box_area_right}>
            <div>
              <div className={styles.box_area_header_big}>Beehaz Plus Plan Total</div>
              <div className={styles.box_area_sub_header}>+ additional discount for beta testers!</div>
            </div>
            <div className={styles.box_area_price_area}>
              <div className={styles.box_area_price}>$ {annual ? `${yearlyPrice}` : monthlyPrice}</div>
              <div className={styles.box_area_price_sub}>{' per month'}</div>
            </div>
            <div className={styles.box_area_sub_header}>
              ({`$${annual ? yearlyPricePaidYear : monthlyPricePaidYear} per year`})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GreyArea;