import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from '../Login/login.module.css';
import Header from "../pages/Header/Header";
import Footer from "../pages/Footer/Footer";
import { Link } from "react-router-dom";
import logo from "../../icons/logo.png";
import { Spinner } from "react-bootstrap";
import { addUserAuthToken, confirmMail } from '../../general_redux/user/actions';

function EmailChange(props) {
    const [otpInp, setOtpInp] = useState();
    const [emailId, setEmailId] = useState("");
    const auth = useSelector(({ user }) => user && user.authToken);
    const confirmMailAction = useSelector(({ user }) => user && user.confirmMailAction);

    const dispatch = useDispatch();
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.token) {
            dispatch(addUserAuthToken(props.match.params.token, "emailChange"))
        }
    }, [props, dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            OTP: Number(otpInp),
            emailId: emailId
        }
        dispatch(confirmMail(payload, auth));
    }

    const handleLogin = (e) => {
        props.history.push("/login");
    }

    return (
        <div>
            <Header redirect />
            {confirmMailAction.success ? (
                <div className="d-flex justify-content-center">
                    <div className={`${styles.mainContainer_success} ${styles.login}`}>
                        <div className={` ${styles.login_wrapper} shadow`}>
                            <div className="d-flex justify-content-center">
                                <Link to="/">
                                    <img src={logo} className={styles.login_image} />
                                </Link>
                            </div>
                            <div className={`justify-content-center ${styles.login_header_success}`}>
                                <h6>Email change verification succeeded: you can now log into your account using the new email address.</h6>
                            </div>
                            <div className={styles.center}>
                                <button className={styles.login_submit} onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : confirmMailAction.error ? (
                <div className="d-flex justify-content-center">
                    <div className={`${styles.mainContainer_success} ${styles.login}`}>
                        <div className={` ${styles.login_wrapper} shadow`}>
                            <div className="d-flex justify-content-center">
                                <Link to="/">
                                    <img src={logo} className={styles.login_image} />
                                </Link>
                            </div>
                            <div className={`justify-content-center ${styles.login_header_success}`}>
                                <h6>Email change verification failed: Please try again or contact support.</h6>
                            </div>
                            <div className={styles.center}>
                                <button className={styles.login_submit} onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="d-flex justify-content-center">
                        <div className={`${styles.mainContainer} ${styles.login}`}>
                            <div className={` ${styles.login_wrapper} shadow`}>
                                <div className="d-flex justify-content-center">
                                    <Link to="/">
                                        <img src={logo} className={styles.login_image} />
                                    </Link>
                                </div>
                                <div className={`d-flex justify-content-center ${styles.login_header}`}>
                                    <h5>Email change verification</h5>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <form onSubmit={handleSubmit}>
                                        <div className={`input-group mb-3 ${styles.input_group}`}>
                                            <div className="input-group-prepend">
                                                <span className={`input-group-text ${styles.input_group_text}`} id="otp">
                                                    OTP
                                                </span>
                                            </div>
                                            <input
                                                onChange={(e) => setOtpInp(e.target.value)}
                                                type="number"
                                                value={otpInp}
                                                className={`form-control ${styles.form_control}`}
                                                aria-label="optNumber"
                                                required
                                            />
                                        </div>
                                        <div className={`input-group mb-3 ${styles.input_group}`}>
                                            <div className="input-group-prepend">
                                                <span className={`input-group-text ${styles.input_group_text}`} id="otp">
                                                    New email address
                                                </span>
                                            </div>
                                            <input
                                                onChange={(e) => setEmailId(e.target.value)}
                                                type="email"
                                                value={emailId}
                                                className={`form-control ${styles.form_control}`}
                                                aria-label="otpEmail"
                                                required
                                            />
                                        </div>
                                        <div className={styles.center}>
                                            <button className={styles.login_submit} type="submit" disabled={confirmMailAction.loading}>
                                                {confirmMailAction.loading ? (
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                            style={{ marginRight: '1em', height: '1.3em', width: '1.3em' }}
                                                        />
                                                        <span>Submit</span>
                                                    </>
                                                ) : (
                                                    <span>Submit</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*<Footer isSticky={true}/>*/}
        </div>
    )
};

export default EmailChange;