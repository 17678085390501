import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onLoad } from "../../../general_redux/actions";
import Main from './components/Main';
import GreyArea from './components/GreyArea';
import FAQ from './components/FAQ';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import styles from './pricing.module.css';

function Home() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(onLoad());
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className={styles.mainContainer}>
                <Main />
            </div>
            <GreyArea />
            <div className={styles.mainContainer}><FAQ /></div>
            <Footer />
        </>
    );
}

export default Home;