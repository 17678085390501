// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* General styles imported in jsx */\n.aboutUs_mainHeader__1aW7O {\n    padding: 20px 0 0 0;\n}\n.aboutUs_subHeaders__1wGH1{\n    padding-bottom: 20px;\n}\n\n.aboutUs_section1__RBdKG, .aboutUs_section2__1v1Pf, .aboutUs_section3__35yPx, .aboutUs_section3Container__1m-lN, .aboutUs_section4__2IoZu{\n    display: flex;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.aboutUs_section2__1v1Pf, .aboutUs_section3__35yPx, .aboutUs_section3Container__1m-lN, .aboutUs_section4__2IoZu{\n    flex-direction: column;\n}\n.aboutUs_section1__RBdKG {\n    text-align: center;\n    justify-content: center;\n}\n\n.aboutUs_section3__35yPx {\n    background-color: var(--lightGreyLv1);\n    padding: 20px 0;\n}\n.aboutUs_section3Container__1m-lN{\n    background-color: var(--lightGreyLv1);\n    align-items: center;\n}\n.aboutUs_section3Image__14bW4 {\n    display: block;\n    width: 100%;\n    height: auto;\n}\n.aboutUs_section3Image__14bW4 img {\n    display: block;\n    width: 100%;\n    height: 100%;\n}\n.aboutUs_section4__2IoZu {\n    /* max-width: 60%; */\n    align-items: center;\n    margin: 25px auto auto;\n}\n", ""]);
// Exports
exports.locals = {
	"mainHeader": "aboutUs_mainHeader__1aW7O",
	"subHeaders": "aboutUs_subHeaders__1wGH1",
	"section1": "aboutUs_section1__RBdKG",
	"section2": "aboutUs_section2__1v1Pf",
	"section3": "aboutUs_section3__35yPx",
	"section3Container": "aboutUs_section3Container__1m-lN",
	"section4": "aboutUs_section4__2IoZu",
	"section3Image": "aboutUs_section3Image__14bW4"
};
module.exports = exports;
