import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { changeCurrentPage, onLoad } from '../../../general_redux/actions';
import MailingList from './Containers/MailingList';
// import ContactUs from '../Login/Containers/ContactUs';
import LogoBhaz from '../../../icons/Logo_Bhaz_1000.png';
import generalStyles from '../websiteMain.module.css';
import styles from './footer.module.css';

function Footer(props) {
  const dispatch = useDispatch();
  const [viewMailing, setViewMailing] = useState(false);
  const [viewAbout, setViewAbout] = useState(false);
  const [viewContactUs, setViewContactUs] = useState(false);

  return (
    <>
      <MailingList
        show={viewMailing}
        onHide={() => {
          setViewMailing(false);
        }}
      />
      <div className={`${styles.footerDiv} ${(props.isSticky ? styles.stickBottom : '')}`}>
        <div className={styles.footerSection1}>
          <div className={styles.footerSection1inner}>
            <Link to={'/contact-us'} className={styles.footer_links}>
              Contact
            </Link>
            {/* <Link to={"/about-us"} className={styles.footer_links}>
              About us
            </Link> */}
            <Link to={"/login"} className={styles.footer_links}>
              Log in
            </Link>
          </div>
          <div className={styles.footerSection1inner}>
            <Link to={"/about-us"} className={styles.footer_links}>
              About us
            </Link>
            <Link to={"/register"} className={styles.footer_links}>
              Sign up
            </Link>
            {/* <a
              className={styles.footer_links}
              href={"https://www.beehaz-resources.com/"}
              target={"_blank"}
            >Resources</a> */}
            {/* <a
              className={styles.footer_links}
              href={"http://www.beehaz-resources.com/sitemap"}
              target={"_blank"}
            >Sitemap</a> */}
          </div>
          <div className={styles.footerSection1inner3}>
            <Link to={"/"} onClick={() => dispatch(changeCurrentPage("Home"))}>
              <img className={styles.footerBhazLogo} src={LogoBhaz} alt="B.Haz logo" />
            </Link>
            <span className={styles.footerIcontext}>Operated by Yaz Häuser AG </span>
          </div>
        </div>
        <div className={styles.footerSection2}>
          <div className={styles.footerSection1inner}>
            <Link className="mx-1" to={'/terms'}>
              Terms of Service
            </Link>
          </div>
          |
          <div className={styles.footerSection1inner}>
            <Link className={'mx-1'} to={'/privacy-policy'}>
              Privacy Policy
            </Link>
          </div>
          |
          <div className={styles.footerSection1inner}>
            <Link className={'mx-1'} to={'/cookie-policy'}>
              Cookie Policy
            </Link>
          </div>
        </div>
        <div className={styles.copyRightsdiv}>
          <span className={styles.copyRightsdivinner}>
            Copyright © 2025{` `}
            <a
              href="https://beehaz.com/"
              target="_blank"
              data-content="https://beehaz.com/"
              data-type="external"
              rel="nofollow noopener"
              style={{ color: '#989696' }}
            >
              BeeHaz
            </a>
            . All rights reserved.
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;