import React, { useEffect } from 'react';
import comma from '../../../icons/comma.svg';
import laptop from '../../../icons/laptop.jpg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import AboutForm from './AboutForm';
import generalStyles from '../websiteMain.module.css';
import styles from './aboutUs.module.css';

function AboutUS(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Header />
            <div className={generalStyles.mainLayout}>
                <section className={generalStyles.container}>
                    <h1 className={`${generalStyles.generalH1} ${styles.mainHeader}`}>About us</h1>
                    <div className={styles.section1}>
                        <span className={`${generalStyles.textQuotation}`}>
                            <img src={comma} className={generalStyles.leftSvgComma} alt="Quotation marks" />
                            <blockquote>
                                Our mission is to make rental management affordable by providing an intuitive software interface that offers the main functionalities business owners need.
                            </blockquote>
                            <img src={comma} className={generalStyles.rightSvgComma} alt="Quotation marks" />
                        </span>
                    </div>
                </section>

                <section className={generalStyles.container}>
                    <div className={styles.section2}>
                        <h2 className={`${generalStyles.generalH2} ${styles.subHeaders}`}>One team, one mission</h2>
                        <p className={generalStyles.generalP}>Beehaz was created by property managers for property managers. We are Airbnb hosts, we are landlords, and we manage furnished rentals for long-term stays.</p>
                        <p className={generalStyles.generalP}>We found no software out there that was capable of accommodating the needs of persons who managed different types of rentals, through different channels, and with a mix of short- and long-term guests and tenants.</p>
                        <p className={generalStyles.generalP}>Envisioning a software that was uncomplicated and affordable, we aimed at designing an intuitive and flexible solution. Short trial periods, high prices, and complicated rate management systems seemed to be everywhere when we searched. We embarked on a mission to solve this problem.</p>
                        <p className={generalStyles.generalP}>The entrepreneur and the small and medium company managers deserve a software that fits their needs and suits their pockets in order to be able to grow and focus on their business.</p>
                    </div>
                </section>

                <section className={generalStyles.container}>
                    <div className={styles.section2}>
                        <h2 className={`${generalStyles.generalH2} ${styles.subHeaders}`}>One team, one mission</h2>
                        <p className={generalStyles.generalP}>Beehaz was created by property managers for property managers. We are Airbnb hosts, we are landlords, and we manage furnished rentals for long-term stays.</p>
                        <p className={generalStyles.generalP}>We found no software out there that was capable of accommodating the needs of persons who managed different types of rentals, through different channels, and with a mix of short- and long-term guests and tenants.</p>
                        <p className={generalStyles.generalP}>Envisioning a software that was uncomplicated and affordable, we aimed at designing an intuitive and flexible solution. Short trial periods, high prices, and complicated rate management systems seemed to be everywhere when we searched. We embarked on a mission to solve this problem.</p>
                        <p className={generalStyles.generalP}>The entrepreneur and the small and medium company managers deserve a software that fits their needs and suits their pockets in order to be able to grow and focus on their business.</p>
                    </div>
                </section>

                <section className={styles.section3}>
                    <div className={`${generalStyles.container} ${styles.section3Container}`}>
                        <h2 className={generalStyles.generalH2}>The rental management software that grows with you</h2>
                        <p className={`${generalStyles.generalP} ${styles.subHeaders}`}>Get inspired!</p>
                        <div className={styles.section3Image}>
                            <img src={laptop} alt="Laptop displaying the Beehaz Blog" />
                        </div>
                        <br />
                        <p className={generalStyles.generalP}>Insights, inspiration, and rental management tips can be found in our blog. Recommendations from professional Airbnb hosts, guides for those starting out, and much more!</p>
                        <p className={generalStyles.generalP}>Our blog is comming up soon! If you have a story to share or would like to know more about hosting, contact us to contribute or ask questions!</p>
                        {/* <a href={"http://www.beehaz-resources.com/blog"} target={"_blank"}>
                            <button className={generalStyles.generalBtnSmaller}>Check out our blog!</button>
                        </a> */}
                    </div>
                </section>

                <section className={generalStyles.container}>
                    <div className={styles.section2} >
                        <h2 className={`${generalStyles.generalH2} ${styles.subHeaders}`}>Growing with you</h2>
                        <p className={generalStyles.generalP}>Beehaz entered the market with the Basic plan in 2024. We are in beta with our Plus Plan version, which should arrive in 2025. Our development efforts will continue, as we believe there is still much more we can offer.​</p>
                        <p className={generalStyles.generalP}>Help us built the best rental software for the best price in the market! Share your thoughts and feedbacks with us.</p>
                    </div>
                </section>

                <section className={generalStyles.container}>
                    <div className={styles.section4}>
                        <h2 className={`${generalStyles.generalH2} ${styles.subHeaders}`}>
                            What features do you believe we should work on?
                        </h2>
                        <AboutForm />
                    </div>
                </section>

            </div>
            <Footer />
        </div>
    );
}

export default AboutUS;