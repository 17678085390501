// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* General styles linked to jsx */\n.iCalExplanation_headerTitle__31HZY {\n    padding: 20px;\n    text-align: center;\n}\n.iCalExplanation_container_question__Fbjg4 {\n    padding: 0px 10px;\n    border-left: 5px solid rgb(245, 182, 14);\n    margin-left: 10px;\n    line-height: 1.2;\n}\n\n.iCalExplanation_container_answer__2Xepq {\n    padding: 5px 10px;\n}\n\n.iCalExplanation_container_answer__2Xepq>p {\n    text-align: justify;\n}\n\n@media (min-width: 500px) {\n    .iCalExplanation_container_question__Fbjg4 {\n        padding: 0 0 0 20px;\n        margin: 10px 0;\n    }\n}", ""]);
// Exports
exports.locals = {
	"headerTitle": "iCalExplanation_headerTitle__31HZY",
	"container_question": "iCalExplanation_container_question__Fbjg4",
	"container_answer": "iCalExplanation_container_answer__2Xepq"
};
module.exports = exports;
