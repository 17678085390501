import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { changeCurrentPage } from '../../../general_redux/actions';
import logo from '../../../icons/Logo_Beehaz.png';
import menuBurger from "../../../icons/menu_bars.svg";
import generalStyles from '../websiteMain.module.css';
import styles from './header.module.css';

function Header(props) {
    const dispatch = useDispatch();
    const refElem = useRef();
    const [mobileView, setMobileView] = useState(false);
    const [defferedPrompt, setDefferedPrompt] = useState(null);
    const myLink = useSelector(({ general }) => general && general.currentRoute);
    const nav = [{
        val: 'Home',
        to: '/'
    }, {
        val: 'Pricing',
        to: '/pricing',
    }, {
        val: 'Features',
        to: '/features'
    }];

    useEffect(() => {
        function handler(event) {
            if (!refElem.current?.contains(event.target)) {
                setMobileView(false);
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    // WPA Install Prompt - START//

    window.addEventListener('beforeinstallprompt', event => {
        console.log('beforeinstallprompt fired');
        event.preventDefault();
        setDefferedPrompt(event);
        return false;
    })

    const pwaInstall = () => {
        if (defferedPrompt) {
            defferedPrompt.prompt().then(result => {
                if (result.outcome === 'dismissed') {
                    console.log('The user has cancelled the installation');
                } else {
                    console.log('The user installed the app');
                }
            })
        }
        setDefferedPrompt(null);
    }

    // WPA Install Prompt - END//

    return (
        <nav>
            <div className={`${styles.headerDiv}`} ref={refElem}>
                <div className={`${styles.headerDivInner} ${styles.container}`}>
                    <div className={`${styles.mainLogo}`}>
                        <Link to={"/"} onClick={() => dispatch(changeCurrentPage("Home"))}>
                            <img
                                src={logo}
                                alt={"Beehaz logo"}
                            />
                        </Link>
                    </div>
                    <div className={`${styles.mainMenu} ${styles.webView}`}>
                        {myLink && nav.map((link, index) => (
                            <div key={index}>
                                <Link
                                    onClick={() => dispatch(changeCurrentPage(link.val))}
                                    className={`${styles.menuList} ${(link.val === myLink) ? styles.active : ""}`}
                                    to={link.to}
                                >
                                    {link.val}
                                </Link>
                            </div>
                        ))}
                    </div>

                    <div className={`d-flex`}>
                        <Link className={`${styles.signOutBtn} ${styles.webView} mr-2`} to={'/login'} onClick={pwaInstall}>Sign in</Link>
                        <Link className={`${styles.signInBtn} ${styles.webView}`} to={'/register'} onClick={pwaInstall}>Sign up</Link>
                    </div>

                    <div className={`${styles.mobileView}`}>
                        <div className={styles.mobileMenubtn} onClick={() => setMobileView(!mobileView)}>
                            <img className={`${styles.mobileMenuBurger}`} src={menuBurger} alt="Mobile menu icon" onClick={() => setMobileView(!mobileView)} />
                        </div>
                        <div className={`${styles.mobileViewmenu} ${mobileView ? styles.show : ""}`}>
                            <div className={`${styles.mainMenu} ${styles.mobileView}`}>
                                {myLink && nav.map((link, index) => (
                                    <div key={index} className={`mb-2`}>
                                        <Link
                                            onClick={() => dispatch(changeCurrentPage(link.val))}
                                            className={`${styles.menuList} ${(link.val === myLink) ? styles.active : ""}`}
                                            to={link.to}
                                        >
                                            {link.val}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <Link className={`${styles.signInBtn} ${styles.mobileView} mb-2`} to={'/register'} onClick={pwaInstall}>
                                Sign up
                            </Link>
                            <Link className={`${styles.signOutBtn} ${styles.mobileView}`} to={'/login'} onClick={pwaInstall}>
                                Sign in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
