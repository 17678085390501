import React from 'react';
import {Button,Modal} from 'react-bootstrap';
import styles from '../../../UserSetting/usersetting.module.css';

function WelcomeModal(props){

    const handleGettingStarted = () => {
        window.open('https://www.youtube.com/watch?v=9ljggH22shY');
        props.onHide()
    }

    return(
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Welcome to BeeHaz!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <p>
                    We have added one property and one rental belonging to that property to your account. 
                    This way, you can start testing the software and the reservation features right away!
                </p>
                <p>
                    Click on the Rentals menu to add, edit and remove properties and rentals. 
                    You may want to edit your rates (especially the default rates) before adding reservations.
                </p>
                <p>
                    The best way to get started is by watching our introductory video: click the button bellow. 
                    If you prefer exploring on your own, just close this message box.
                </p>
                <p>
                    Whenever you need to access our help page and guides, click on the question mark icon in the top menu bar!
                </p>
            </Modal.Body>
            <div className={styles.modalFooter}>
                <button onClick={handleGettingStarted} className={styles.savBtn}>Getting started</button>
            </div>
        </Modal>
    )
}
export default WelcomeModal;