import React from 'react';
import styles from '../pricing.module.css';
import generalStyles from '../../websiteMain.module.css';
import { Link } from 'react-router-dom';

function Main() {
  return (
    <section>
      <div className={styles.container_head}>
        <h1 className={`${generalStyles.generalH1} ${styles.pricingH1}`}>Rental management made affordable</h1>
      </div>
      <div className={styles.container_content}>
        <p className={`${generalStyles.generalP}`}>
          Start for free and decide later. 2 months free trial. No credit card details. No set-up or hidden fees.
          Sign up and start testing in under 5 minutes. Our plans grow with your business.
        </p>
      </div>
      <div className={`d-flex justify-content-center ${styles.container_card}`}>
        <div className={styles.card}>
          <div className={styles.card_header}>Basic Plan</div>
          <div className={styles.card_price}>1 Rental Free</div>
          <div className={styles.card_sub_header}>1€ + 1€/rental for 2+ rentals*</div>
          <div className={styles.card_content}>
            <ul>
              <li >Reservation system (PMS)</li>
              <li>Infinite scroll calendar</li>
              <li>Guest management (CRM)</li>
              <li>Custom invoicing</li>
              <li>Chat and email support</li>
              <li>Maximum of 50 rentals</li>
            </ul>
          </div>
          <div className={styles.card_container_button}>
            <Link to="/register" className={styles.card_button}>
              Sign up!
            </Link>
          </div>
        </div>
        <div className={`${styles.card} position-relative`}>
          <div className={styles.card_header}>Plus Plan</div>
          <div className={styles.card_price}>Soon!</div>
          <div className={styles.card_sub_header}>15€ + 7€/rental*</div>
          <div className={styles.card_content}>
            <ul>
              <li>Reservation system (PMS)</li>
              <li>Infinite scroll calendar</li>
              <li>Guest management (CRM)</li>
              <li>Custom invoicing</li>
              <li>Chat and email support</li>
              <li>Soon: Channel Manager (API)</li>
              <li>Soon: Stripe connection</li>
              <li>Maximum of 50 rentals</li>
            </ul>
          </div>
          <div className={styles.card_container_button}>
            <Link to="/register" className={styles.card_button}>
              60-days free trial!
            </Link>
          </div>

          <div className={styles.pricebox} >
            50% off
          </div>

        </div>
      </div>
      <div className={styles.note}>
        <span>*per month paid yearly, includes all fees and charges.</span>
      </div>
      <div className={styles.container_content}>
        {/* <p className={`${generalStyles.generalP}`}>
          When you sign up you will automatically enter the Plus Plan 60-days free trial.
          There is no obligation to continue with the paid version: after the trial period expires, you will be downgraded to the Basic Plan automatically.
          Upgrade to the Plus Plan in the user settings to continue using the Plus Plan features without interruption.
        </p> */}
        <p className={`${generalStyles.generalP}`}>
          Beehaz is still in beta! The Basic Plan is ready for you: you can start your free trial now: no credit card required.
          The free trial will remain free until we finish the latest features of the Plus Plan. You will be notified 60 days before we start charging!
          Contact us if you are using the software and help us design it the way you need it!
        </p>
        <br />
        <h2 className={`${generalStyles.generalH2}`}>
          Hassle free. Transparent pricing. Simple to use.
        </h2>
        <br />
        <p className={`${generalStyles.generalP}`}>
          Our Plus Plan will start at 15€ fixed + 7€ per rental unit (be that rooms, apartments, bedrooms, or parking spaces), all charges included.
          That means 22€ if you have 1 rental, 50€ if you have 5 rentals, or 120€ if you have 15 rentals!
          For those who do not require OTA connection, our Basic Plan is even better: free for one rental, and just 6€ for 5 rentals per month (paid yearly)!
          Our fixed-term booking feature is perfect for all those who rent on a weekly or monthly basis!
        </p>
        {/* <p className={`${generalStyles.generalP}`}>
          Limited-time offer: enjoy all features at a 50% discount for the first 2 years!
          Our regular prices are 3.00 USD per rental per month on yearly payments.
        </p> */}
        <p className={`${generalStyles.generalP}`}>
          When we launch, we shall offer 10% discount for yearly payments.
          If you are parf of our beta program, contact us to get a 50% discount at launch!
        </p>
        <p className={`${generalStyles.generalP}`}>
          If you manage more than 50 units, please <a href="/contact-us" className={`${generalStyles.generalA}`}>contact us</a> for a quote!
        </p>
      </div>
      <br />
    </section>
  );
}

export default Main;
