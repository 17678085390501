import React from 'react';
import styles from '../pricing.module.css';
import generalStyles from '../../websiteMain.module.css';
import { Link } from 'react-router-dom';

function FAQ() {
    return (
        <section className={styles.faqSection}>
            <div className={styles.container_head}>
                <h2 className={generalStyles.generalH2}>Frequently asked questions</h2>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>What is a rental management software?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    A rental management software is a tool that provides efficiency and organization to rental businesses. A short-term rental (STR) software serves as a centralized platform for properties, rooms, or apartments that are rented out by the night, week, month, or even several months at a time.
                </p>
                <p className={generalStyles.generalP}>
                    With Beehaz, you can take reservations priced per night, per week, or per month. You are also able to input several fixed-term (weekly or monthly) bookings from the same guest at a time - a great feature for mid- and long-term stays where guests pay per billing cycle!
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>Can I connect Beehaz to Airbnb, Booking.com, and VRBO?</h3>
            </div>
            <div className={styles.container_answer}>
                {/* <p className={generalStyles.generalP}>
                    Yes, you can synchronize your Beehaz calendar with channels such as Airbnb, Booking.com, VRBO, and many others via iCal links. This way, whenever you get a booking in one channel, this information will be transmitted to Beehaz, who will block those dates on your Beehaz calendar, as well as on the other channels you are connected to. Rates are not synchronized at the moment. You can read more about iCal connection by 
                    clicking <a className={generalStyles.generalA} target="_blank" href="/ical-info">here</a>. Manual changes are a thing of the past. You can also link your Behaz calendar to Google this way!
                </p> */}
                <p className={generalStyles.generalP}>
                    Yes, you can synchronize your Beehaz calendar with channels such as Airbnb, Booking.com, VRBO, and many others via API connection. If you already have an account with those channels, synchronization will be easy!
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>I do not list my rentals in Online Travel Agencies (OTAs). Can I still profit from Beehaz?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    Absolutely. You can use Beehaz to take direct reservations. You can colour-code your bookings, check payment status, invoice your tenants or guests, download reservation lists in excel to send to your accountant, save guest information, and much more. Beehaz can help you keep organized!
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>Do I need a merchant account in order to accept credit card payments?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    No. All you need in order to accept credit card payments is a Stripe account, and you can register for free.
                    We are working on a Stripe integration as you read this!
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>Do I need to download the Beehaz software? Do I have to sign a contract?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    No, you do not have to download the software because Beehaz is cloud-based. This means you can access your Beehaz account from any computer, tablet, or phone. All you need is an internet connection and you are ready to log in!
                </p>
                <p className={generalStyles.generalP}>
                    You also do not have to sign a contract. You can sign up and start using Beehaz right away, without having to enter your credit card details or billing information. Shall you decide to switch to the paid version, you can cancel at any time. For more information about our policies, please read our
                    <a className={generalStyles.generalA} href="/terms"> terms of service</a>.
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>How safe is Beehaz?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    Beehaz does not store passwords, billing, or credit card information. This makes Beehaz a safe software to use. You can sign in using either your email address (via token), or through Google or Facebook verification. Invoice credit card processing, if set up, are paid through Stripe. Our cloud software is hosted in Amazon Web Services (AWS), which is very secure. No software or device connected to the internet is unhackable, but we make sure to take the necessary steps to make our services as secure as possible.
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>Can I access Beehaz through my phone?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    Yes, you can log in from any device. When you access the software from a phone, you will have the same functionality as when you access it from your computer. This is because you are using a Progressive Web Application (PWA) instead of a native app.
                    In a nutshell, the differences are: direct installation process (from our website rather than from an app store), instant updates, loads faster even with poorer connectivity, and PWAs take less space on your phone than their native apps counterparts. If you prefer not to install the application, you can still access the software from the browser on your phone.
                </p>
            </div>
            <div className={styles.container_question}>
                <h3 className={generalStyles.generalH3}>I still have questions. What is the best way to contact Beehaz?</h3>
            </div>
            <div className={styles.container_answer}>
                <p className={generalStyles.generalP}>
                    We look forward to answering any questions you may have!
                    You can send your questions via our <a className={generalStyles.generalA} href="/contact-us">contact form</a> or write to us via live chat!
                    Please note that our business hours are presented in Central European Time (CET). If you do not find an agent online, leave us a message and we will get back to you as soon as possible!
                </p>
            </div>

        </section>
    );
}

export default FAQ;